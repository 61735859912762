import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { PortalProps } from './types';

function Portal({ children, elementId }: PortalProps) {
  const [el, setEl] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const mount = document.getElementById(elementId);
    const el = document.createElement('div');
    setEl(el);

    if (mount) {
      mount.appendChild(el);
    }

    return () => {
      if (mount) {
        mount.removeChild(el);
      }
    };
  }, [elementId]);

  if (!el) {
    return null;
  }

  return createPortal(children, el);
}

export default Portal;
