import React, { useEffect, useState } from 'react';
import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps';
import ymaps from 'yandex-maps';
import mean from 'lodash/mean';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { media } from '@/utils/mixin';
import Portal from '@/components/Redesign/Portal';
import DealerCard from '@/components/Redesign/DealerCard';
import GeoIcon from '@/assets/svg/geo.svg?url';
import { BalloonStyles } from '@/components/Redesign/styles';

import { YandexMapProps } from './types';

function YandexMap({ dealers }: YandexMapProps) {
  const isTablet = useMedia('(max-width: 1259px)');
  const [activePortal, setActivePortal] = useState('');
  const [defaultMapState, setDefaultMapState] = useState<ymaps.IMapState>({});

  useEffect(() => {
    setActivePortal('');
    setDefaultMapState({
      center: [
        mean(dealers?.map((dealer) => dealer.latitude)),
        mean(dealers?.map((dealer) => dealer.longitude)),
      ],
      zoom: isTablet ? 11 : 12,
    });
  }, [dealers, isTablet]);

  return (
    <Component>
      <BalloonStyles />
      <YMaps
        version={'2.1.79'}
        query={{ apikey: process.env.NEXT_PUBLIC_API_KEY_YANDEX_MAP }}
      >
        <Map
          state={defaultMapState}
          width="100%"
          height="100%"
          style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
        >
          {dealers?.map((dealer, index) => (
            <React.Fragment key={`${dealer.name}-${index}`}>
              <Placemark
                modules={['geoObject.addon.balloon']}
                geometry={[dealer.latitude, dealer.longitude]}
                options={{
                  iconLayout: 'default#image',
                  iconImageSize: [30, 30],
                  iconImageHref: GeoIcon,
                }}
                properties={{
                  balloonContentBody: `<div id='${dealer.name}' style="width: 400px; height: 120px;"></div>`,
                }}
                onClick={() => {
                  setTimeout(() => {
                    setActivePortal(dealer.name);
                  }, 0);
                }}
              />
              {activePortal === dealer.name && (
                <Portal elementId={dealer.name}>
                  <DealerCardMapWrapper>
                    <DealerCard hasShadow isNewTab {...dealer} />
                  </DealerCardMapWrapper>
                </Portal>
              )}
            </React.Fragment>
          ))}
        </Map>
      </YMaps>
    </Component>
  );
}

export default YandexMap;

const Component = styled.div`
  position: relative;
  padding-top: 40.7%;
  border-radius: 20px;
  overflow: hidden;

  ${media.tablet(css`
    padding-top: 107%;
    border-radius: 0;
  `)}
`;

const DealerCardMapWrapper = styled.div`
  position: fixed;
  width: 400px;
`;
