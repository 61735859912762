import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import CarCheckForm from '@/components/Redesign/Forms/CarCheckForm';
import { useRefitData } from '@/modules/RefitLanding/hooks';
import { CarCheckFormValues } from '@/components/Redesign/Forms/CarCheckForm/types';
import { submitRefitConsultation } from '@/services/leads/leads-service';
import { ContentContainer } from '@/modules/RefitLanding/common.styles';

function Form() {
  const {
    templateFields: { formTitle, formImage, formImageMobile, formDealer },
  } = useRefitData();

  if (!formTitle) {
    return null;
  }

  const fieldsConfig = {
    withMessage: true,
  };

  const sendRefitForm = async (values: CarCheckFormValues) => {
    try {
      const { message, name, phone } = values;
      await submitRefitConsultation({
        name,
        phone,
        message,
        dealer: formDealer[0].crmId,
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Component>
      <ContentContainer>
        <CarCheckForm
          image={formImage}
          mobileImage={formImageMobile}
          title={formTitle ?? ''}
          fieldsConfig={fieldsConfig}
          isAbsoluteImage
          isCenterImage
          onSubmit={sendRefitForm}
        />
      </ContentContainer>
    </Component>
  );
}

export default Form;

const Component = styled.section`
  margin-top: 60px;

  ${media.tablet(css`
    margin-top: 40px;
  `)}
`;
