import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';

import { media } from '@/utils/mixin';
import HeroBanner from '@/components/Redesign/HeroBanner';
import ConsultationModal from '@/components/Redesign/modals/ConsultationModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { ConsultationModalFields } from '@/components/Redesign/modals/ConsultationModal/types';
import { submitRefitConsultation } from '@/services/leads/leads-service';
import { ContentContainer } from '@/modules/RefitLanding/common.styles';
import { useRefitData } from '@/modules/RefitLanding/hooks';

function Hero() {
  const openModal = useModal();
  const {
    templateFields: {
      heroTitle,
      heroImage,
      heroMobileImage,
      heroAdvantages,
      heroButtonText,
      formDealer,
    },
  } = useRefitData();

  if (!heroTitle || !heroAdvantages?.length) {
    return null;
  }

  const advantages = heroAdvantages.map((advantage) => ({
    title: advantage.title ?? '',
    subtitle: advantage.subtitle ?? '',
  }));

  const sendConsultationModal = async (values: ConsultationModalFields) => {
    try {
      await submitRefitConsultation({
        ...values,
        dealer: formDealer[0].crmId,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleOpenModal = () => {
    openModal(
      ConsultationModal,
      {
        title: 'Получить консультацию',
        request: sendConsultationModal,
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  return (
    <Component>
      <ContentContainer isBig>
        <HeroBanner
          title={heroTitle}
          advantages={advantages}
          isBlackColor
          buttonText={heroButtonText ?? ''}
          image={heroImage}
          tabletImage={heroMobileImage}
          buttonVariant={'contained'}
          buttonOnClick={handleOpenModal}
        />
      </ContentContainer>
    </Component>
  );
}

export default Hero;

const Component = styled.section`
  margin-top: 20px;

  ${media.tablet(css`
    margin-top: 0;

    ${ContentContainer} {
      padding: 0;
    }
  `)}
`;
