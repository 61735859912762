import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import Banner from '@/components/Redesign/Banner';
import { useRefitData } from '@/modules/RefitLanding/hooks';
import { colors } from '@/constants/theme';
import { ContentContainer, Title } from '@/modules/RefitLanding/common.styles';

function Advantages() {
  const {
    templateFields: {
      advantagesTitle,
      qualityText,
      qualityImage,
      guaranteeText,
      guaranteeImage,
      individualText,
      individualImage,
    },
  } = useRefitData();

  if (!advantagesTitle) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{advantagesTitle}</Title>
        <BannersWrapper>
          <Banner
            title={qualityText ?? ''}
            color={colors.purpleGradient200}
            size={'small'}
            orientation={'horizontal'}
            image={qualityImage}
          />

          <Banner
            title={guaranteeText ?? ''}
            color={colors.pinkGradient100}
            size={'small'}
            orientation={'horizontal'}
            image={guaranteeImage}
          />

          <Banner
            title={individualText ?? ''}
            color={colors.blueGradient100}
            size={'small'}
            orientation={'horizontal'}
            image={individualImage}
          />
        </BannersWrapper>
      </ContentContainer>
    </Component>
  );
}

export default Advantages;

const Component = styled.section`
  margin-top: 60px;

  ${media.tablet(css`
    margin-top: 40px;
  `)}
`;

const BannersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  ${media.tablet(css`
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  `)}
`;
